<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="800" persistent>
      <template v-slot:activator="{ on }">
        <v-btn small color="error" v-on="on" dark @click="ListarEstudiantes">Editar NSP</v-btn>
      </template>
      <v-card>
        <v-card-title>Registro de Notas: {{ Materia }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert dense border="left" type="warning" >La nota <strong>'0'</strong> representa <strong>'NSP' (No se presentó)</strong> </v-alert>
          <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="50px">Nro</th>
                <th>Estudiante</th>
                <th width="200px">Nota</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Item in Listado" :key="Item.IdPersona" >
                <td>{{ Item.Nro}}</td>
                <td>{{ Item.Estudiante }}</td>
                <td><v-text-field v-model="Item.NotaFinal" type="number" min="0" max="100" :rules="[rules.counter]" dense></v-text-field></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="RegistrarNotas" :loading="BloqBtn">Guardar</v-btn>
          <v-btn @click="dialog=false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name:'Notas',
  props:{
    Para:Number,
    Version:Number,
    Materia:String,
  },
  data(){
    return{
      dialog:false,
      items:[],
      rules:{
        counter: value=>(parseInt(value)>=0&&parseInt(value)<=100) ||'La nota debe ser entre 0 y 100'
      },
      Listado:[],
      Resp:[],
      Acta:false,
      Cargando:false,
      BloqBtn:false,
    }
  },

  methods:{
    ListarEstudiantes:function(){
      this.$http.get('/score/listarestudiantesnsp/'+this.Para+'/'+this.Version)
        .then(response => {
          this.Listado=response.data;
          var Regs = this.Listado.filter(function(Item){
            if (!Item.Registrado) return Item;
          });
          if(Regs.length>0){
            this.Acta=true;
          }
        }).catch(err => {
          if (err.response.status==401){
            this.$router.push('/Login');
          }
        });
    },

    ForzarDescarga:function(res,tipo){
      const url =window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',tipo+'-'+this.Materia+'.pdf');
      document.body.appendChild(link);
      link.click();
    },

    DescargarArchivo:function(id,ver,tipo){
      this.Cargando=true;
      this.$http.get('document/'+tipo+'/'+id+'/'+ver,{responseType: 'arraybuffer',})
        .then(response=>{
          this.ForzarDescarga(response,tipo);
          this.Cargando=false;
        }).catch(err=>{
          if(err.response.status==401)
          {
            this.$router.push('/Login');
          }
        });
    },

    async RegistrarNotas(){
      //Verificar que exista alguna nota que registrar.

      let Res = await this.$dialog.confirm({
        text:'¿Esta seguro que desea registrar las notas? tenga en cuenta que la nota 0 representa NSP',
        title:'Consulta',
        persistent:true,
        actions:{
          false:'No',
          true:{
            text:'Si'
          }
        }
        });
      if (Res)
      {
        this.BloqBtn=true;
        this.$http.post('/score/regnotasnsp/'+this.Version,{Listas:this.Listado})
        .then(response=>{
          this.Resp=response.data;
          if (!this.Resp.Error){
            this.BloqBtn=false;
            alert('Se registraron las notas');
            this.dialog=false;
          }
          else{
            alert('Ocurrio un error en el registro: '+ this.Resp.Mensaje);
          }
        })
      }
    },



  },
  mounted(){}

}
</script>