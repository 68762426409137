<template>
  <div class="Inicio">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    Este es el Inicio.
  </div>
</template>

<script>


export default {
  name: 'home',
  data(){
    return{

    }
  },
  mounted() {
    let Per = this.$store.state.Usuario;
    if (Per.Token==''){
      this.$router.push('/Login');
    }
    
  },
}
</script>
