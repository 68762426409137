<template>
  <v-layout column align-center sm8 class="ma-8">
    <v-form>
      <v-card class="elevation-12">
        <v-toolbar color="blue-grey" dark flat>
          <v-toolbar-title>Autenticación</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field label="Usuario" v-model="Username" prepend-icon="mdi-account"></v-text-field>
          <v-text-field label="Contraseña" :type="'password'" v-model="Pwd" prepend-icon="mdi-lock-question"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="autenticar" :loading="cargando">Acceder</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <div>{{ Lista }}</div>  
  </v-layout>
</template>

<script>
export default {
  name:'Login',
  data(){
    return{
      Username:'',
      Pwd:'',
      Lista:'',
      cargando: false
    }
  },

  methods:{
    autenticar:function(){
      this.cargando=true;

      this.$http.post('/login/authenticate',{Username:this.Username,Password:this.Pwd})
           .then(response=>{
             this.Lista=response.data;
             this.$store.commit('AsignaDatosUser',{
               IdPersona:this.Lista.IdPersona,
               Nombre:this.Lista.Usuario,
               Email:this.Lista.Cuenta,
               NroIdentidad:this.Lista.NroIdentidad,
               Token:this.Lista.Token,
               Foto:this.Lista.Foto
             });
             //alert(JSON.stringify(this.Lista) );
           })
           .finally(()=>{
             this.cargando=false;
             this.$router.push('/');
           })

    }
  }

}
</script>
