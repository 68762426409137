<template>
  <v-card>
    <v-card-title>
      Listado de Materias
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row align="center">
            <v-col cols="12" md="2">
              <v-select label="Sede" class="ma-2" :items="Sedes" item-text="Nombre" item-value="IdSedeAcademica" :loading="Loading" v-model="SelSede"></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select label="Gestión" class="ma-2" :items="Gestiones" item-text="Romano" item-value="GestionSem" :loading="Loading" v-model="SelGestion"></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn small color="primary" :loading="Loading" @click="ListarMaterias">Listar</v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nro</th>
                <th class="text-left">Materia</th>
                <th class="text-left">Modulo</th>
                <th class="text-left">Turno</th>
                <th class="text-left">Notas</th>
                <th class="text-left">Corrige NSP</th>
                <th class="text-left">Version</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="Item in Materias" :key="Item.Nro">
                <td>{{ Item.Nro }}</td>
                <td>{{ Item.Materia }}</td>
                <td>{{ Item.Modulo }}</td>
                <td>{{ Item.Turno }}</td>
                <td><Notas :Para="Item.IdParalelo" :Version="Item.Version" :Materia="Item.Materia"/></td>
                <td><EditNSP :Para="Item.IdParalelo" :Version="Item.Version" :Materia="Item.Materia"/></td>
                <td>{{ Item.Version }}</td>
              </tr>
              
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Notas from '../components/Notas'
import EditNSP from '../components/EditNSP'

export default {
  name:'Materias',
  components:{Notas, EditNSP},

  data(){
    return{
      //Auto:{'headers':{'Authorization':'Bearer '+this.$store.state.Usuario.Token,}},
      Loading:false,
      Sedes:[],
      Gestiones:[],
      Materias:[],
      SelSede:{},
      SelGestion:{},
    }
  },

  methods:{
    ListarMaterias:function(){
      this.Loading=true;
      //const axios = require('axios');
      let Head = '/'+this.SelSede+'/'+this.SelGestion;
      this.$http.get('/score/listarmaterias'+Head)
           .then(response=>{
             this.Materias=response.data;
           });


      this.Loading=false;

    },



  },

  mounted(){
    //Se carga la Sede y Gestion del docente.
    this.Loading=true;
    //const axios=require('axios');
    this.$http.get('/score/listarsede')
         .then(response=>{
            this.Sedes=response.data;
            //Se carga las gestiones
            this.$http.get('/score/listargestiones')
                 .then(response=>{
                   this.Gestiones=response.data;
                 })
                 .catch(error=>{
                   if (error.response.status==401)
                   {
                     this.$router.push('/Login');
                   }
                 });
                 
         })
         .catch(error=>{
           if (error.response.status==401)
           {
             this.$router.push('/Login');
           }
           
         });
    this.Loading=false;
  }


}
</script>