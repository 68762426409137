import Vue from 'vue'
import Vuex from 'vuex'
//import { stat } from 'fs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Usuario:{
      IdPersona:0,
      Nombre:'USUARIO SIN AUTENTICAR',
      Email:'',
      NroIdentidad:0,
      Token:'',
      Foto:''
    }
  },
  mutations: {
    AsignaDatosUser(state,{IdPersona,Nombre,Email,NroIdentidad,Token,Foto}){
      let Per=state.Usuario;
      Per.IdPersona=IdPersona;
      Per.Nombre=Nombre;
      Per.Email=Email;
      Per.NroIdentidad=NroIdentidad;
      Per.Token=Token;
      Per.Foto=Foto;
    },
    Logout(state){
      let Per=state.Usuario;
      Per.IdPersona=0;
      Per.Nombre='USUARIO SIN AUTENTICAR';
      Per.Email='';
      Per.NroIdentidad='0';
      Per.Gestion=0;
      Per.GesRom='NO DEFINIDO';
      Per.Token='';
      Per.Version=0;
      Per.IdSede=0;
      Per.Foto='';
    }
  },
  actions: {
  },
  modules: {
  }
})
