import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VuetifyDialog from 'vuetify-dialog'
import axios from 'axios'
//Agregado para mostrar Dialogos de confirmacion.
Vue.use(VuetifyDialog);

Vue.config.productionTip = false
//agregado por el programador.
axios.defaults.baseURL='https://dfun.usfa.edu.bo:49231/api'; //'http://localhost:5051/api':49231

axios.interceptors.request.use(
  (config)=>{
    let token = store.state.Usuario.Token;
    if (token){
      config.headers['Authorization']='Bearer ' + token;
    }

    return config;
  },
  (error)=>{
    return Promise.reject(error);
  });

Vue.prototype.$http = axios
//Fin del agregado por el programador

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
